.avatar {
    display: flex;
    align-items: "center";
}
.avatar>div img{
    border-radius: 50%;
    object-fit: cover;
    height: 150px;
    width: 150px;
}
@media only screen and (max-width: 600px) {
    .avatar {
        display: block;
    }
    .avatar>div{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}